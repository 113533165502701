import { Controller } from 'stimulus'
import { getQuery } from 'helpers/requests'
export default class extends Controller {
  static targets = ['submitButton']

  connect () {
    this.checkSubmitButton()
  }

  async clickCardSimple (e) {
    // Always wrap async function in try..(?catch)..finally
    try {
      const cardClicked = e.currentTarget
      const inputClicked = cardClicked.children[0]
      const PreviousCardsChecked = document.querySelector('.CardRadio__Input:checked')

      if (inputClicked === PreviousCardsChecked) {
        if (!document.querySelector(`[for='${PreviousCardsChecked.name}'] #counter`)) {
          this.changeValueInput(inputClicked)
        }
      } else {
        this.removePreviousCardsChecked(PreviousCardsChecked)
        this.changeValueInput(inputClicked)
      }

      const inputChildCounter = document.querySelector(`[for='${inputClicked.name}'] #counter`)
      const employeePriceSelector = document.querySelector(`[for='${inputClicked.name}'] [data-select='employeeCost']`)
      if (inputChildCounter && employeePriceSelector) {
        const response = await this.computeNewCardPrice(inputClicked)
        this.updateAccordeonRecapsText(response.employee_price, response.user_pro_price)
        inputChildCounter.classList.remove('d-none')
        this.checkSubmitButton()
        return
      }

      const userProPriceSelector = document.querySelector(`[for='${inputClicked.name}'] [data-select='userProCost']`)
      if (employeePriceSelector && userProPriceSelector) {
        const employeePrice = employeePriceSelector.textContent
        const userProPrice = userProPriceSelector.textContent

        this.updateAccordeonRecapsText(employeePrice, userProPrice)
      }
    } finally {
      // Always check submit button whether there is an error or not.
      this.checkSubmitButton()
    }
  }

  changeValueInput (input) {
    if (input.value === 'true') {
      input.value = false
      input.checked = false
    } else {
      input.value = true
      input.checked = true
    }
  }

  removePreviousCardsChecked (PreviousCardsChecked) {
    if (PreviousCardsChecked) {
      this.changeValueInput(PreviousCardsChecked)
      const previousChildCounter = document.querySelector(`[for='${PreviousCardsChecked.name}'] #counter`)
      if (previousChildCounter) {
        previousChildCounter.classList.add('d-none')
        this.removeCounterDatas(PreviousCardsChecked)
        if (document.querySelector(`[for='${PreviousCardsChecked.name}'] [data-select='employeeCost']`)) {
          this.computeNewCardPrice(PreviousCardsChecked)
        }
      }
    }
  }

  checkSubmitButton () {
    const CardsChecked = document.querySelector('.CardRadio__Input:checked')
    if (CardsChecked) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  removeCounterDatas (PreviousCardsChecked) {
    document.querySelector(`[for='${PreviousCardsChecked.name}'] [data-select='labelFieldCounter']`).textContent = '1 enfant'
    document.querySelector(`[for='${PreviousCardsChecked.name}'] [data-select='inputFieldCounter']`).value = 1
  }

  async computeNewCardPrice (card) {
    const numberOfChild = document.querySelector(`[for='${card.name}'] [data-select='inputFieldCounter']`).value
    const response = await getQuery(`/espace-salarie/affiliations-collective/prix-cotisation-formule?cotisation_structure=${card.name}&number_of_child=${numberOfChild}`)

    this.updateCardPriceText(card, response)

    return response
  }

  updateCardPriceText (card, response) {
    document.querySelector(`[for='${card.name}'] [data-select='employeeCost']`).textContent = response.employee_price
    document.querySelector(`[for='${card.name}'] [data-select='userProCost']`).textContent = response.user_pro_price
  }

  updateAccordeonRecapsText (employeePrice, userProPrice) {
    const accordeonRecapEmployeeCostElements = document.querySelectorAll('.AccordeonRecap [data-select=\'employeeCost\']')
    accordeonRecapEmployeeCostElements.forEach((el) => {
      el.textContent = employeePrice
    })

    const accordeonRecapUserProCostElements = document.querySelectorAll('.AccordeonRecap [data-select=\'userProCost\']')
    accordeonRecapUserProCostElements.forEach((el) => {
      el.textContent = userProPrice
    })
  }
}
